<script setup>
import { defineProps, defineEmits, onMounted, ref } from 'vue';
import { HUB } from '@/hub'
import { Gems } from "@/gems";
import defaultImg from "../images/default.png";
import store from '../store';

const props = defineProps(['userId'])
const emits = defineEmits(['control-user-modal', 'update-user-data']);
const userData = ref({});
const internal = ref("");
const professionalName = ref("");
const fullName = ref("");
const genderId = ref('null');
const categoryId = ref('null');
const jobId = ref('null');
const email = ref("");
const username = ref("");
// const costHour = ref("");
// const hourValue = ref("");
// const dailyHours = ref("");
const isAdmin = store.getters.User.admin;
let hasTeams = ref(false);
let hasIntime = ref(false);
let hasHeadship = ref(false);

function HideModal() {
    emits('control-user-modal')
}

onMounted(async () => {

    hasIntime.value = store.getters.User.apps?.intime?.active == 1;

    const response = await HUB.CallBackEnd({
        action: HUB.Action("get-profile-dependencies"),
        data: { user_id: props.userId, has_intime: hasIntime.value }
    });

    if (props.userId) {
        genderId.value = response.data.user.gender_id
        categoryId.value = response.data.user.category_id
        jobId.value = response.data.user.job_id
        internal.value = response.data.user.internal
        professionalName.value = response.data.user.professional_name
        fullName.value = response.data.user.full_name
        username.value = response.data.user.username
        email.value = response.data.user.email
        // costHour.value = response.data.user.cost_hour
        // hourValue.value = response.data.user.hour_value
        // dailyHours.value = response.data.user.daily_hours
    }

    userData.value = response.data;

    if (Object.keys(userData.value.teams).length > 0)
        hasTeams.value = true;

    if (Object.keys(userData.value.hierarchy).length > 0)
        hasHeadship.value = true;

    Gems.Tooltip(document.getElementById('icon-close-modal'), 'Fechar', 'tooltip');

    if (isAdmin)
        Gems.Tooltip(document.getElementById('image-user'), 'Mudar a foto', 'tooltip');

    const divLeftHeight = document.getElementById('div-left').offsetHeight;
    const divRightHeight = document.getElementById('div-right');

    if (divLeftHeight > divRightHeight.offsetHeight)
        divRightHeight.style.height = `${divLeftHeight}px`
})

async function UpdateUserInfo() {
    let fileImage = document.getElementById('input-img-user').files;

    if (!CheckFile(fileImage[0]))
        return

    const formData = new FormData();
    formData.append("image", fileImage[0]);

    if (HUB.RequiredFieldsValid()) {
        formData.append('input', JSON.stringify({
            user_id: props.userId
            , internal: internal.value
            , name: professionalName.value
            , full_name: fullName.value
            , gender_id: genderId.value
            , category_id: categoryId.value
            , job_id: jobId.value
            , username: username.value
            , email: email.value
            // , cost_hour: costHour.value
            // , hour_value: hourValue.value
            // , daily_hours: dailyHours.value
            , has_intime: hasIntime.value
        }))

        const response = await HUB.CallBackEnd({
            action: HUB.Action("update-user-info"),
            data: formData
        })

        if (!response.error) {
            Gems.Telegram("Dados atualizados", 'tele-success')

            if (props.userId == store.getters.User.id && fileImage[0])
                store.getters.User.photo = URL.createObjectURL(fileImage[0])

            emits('control-user-modal', {
                modalInfo: false
            });

            emits('update-user-data', {
                internal: internal.value
                , name: professionalName.value
                , full_name: fullName.value
            });
        }
    }
}

function UpdateImage() {

    if (!isAdmin && userData.value.user.can_upload_photo != 1)
        return

    const inputImage = document.getElementById('input-img-user')

    inputImage.click();
}

async function SelectFile(e) {
    userData.value.user.src = URL.createObjectURL(e.target.files[0])

    if (!isAdmin && userData.value.user.can_upload_photo == 1)
        UpdateUserInfo()
}


function CheckFile(file) {
    if (file?.size > 10485760) {
        Gems.Telegram("Tamanho máximo permitido: 10MB");
        return false;
    }

    if (ProhibitedExtensions(file?.name)) {
        document.getElementById("input-img-user").value = "";
        Gems.Telegram("Carregue um ficheiro válido", 'tele-not-allowed');
        return false;
    }

    return true;
}

function ProhibitedExtensions(extension) {
    const hasProhibited = extension?.split('.').filter(ext => {
        return ['exe', 'php', 'deb', 'tar', 'iso'].includes(ext)
    })

    return hasProhibited?.length > 0 ? true : false
}

</script>

<template>
    <div class="div-modal">
        <div class="div-modal-content">
            <div class="div-container">

                <div class="div-user-content">
                    <div class="div-close-modal">
                        <i id="icon-close-modal" class="fas fa-times clickable" @click="HideModal()"></i>
                    </div>

                    <div class="div-user-image-container">
                        <div class="div-user-image-content">
                            <div class="div-user-image inputs-border" :class="{ 'hover-inputs-border': isAdmin }">
                                <img id="image-user" :src="userData?.user?.src ?? defaultImg" alt="user-image"
                                    :class="{ 'default-cursor': !isAdmin && userData?.user?.can_upload_photo != 1 }"
                                    @click="UpdateImage()">
                                <input id="input-img-user" type="file" class="hidden" @change="SelectFile($event)">
                            </div>
                        </div>
                    </div>
                </div>

                <div class="div-user-info-container">


                    <div id="div-left" class="div-left">
                        <div class="div-left-container">
                            <div class="div-row">
                                <div class="div-internal">
                                    <label>N° INTERNO</label>
                                    <div class="">
                                        <input type="text" v-model="internal" required
                                            :class="{ 'disabled': !isAdmin }">
                                    </div>
                                </div>
                                <div class="div-professional-name">
                                    <label>NOME PROFISSIONAL</label>
                                    <div class="">
                                        <input type="text" v-model="professionalName" :class="{ 'disabled': !isAdmin }">
                                    </div>
                                </div>
                            </div>

                            <div class="div-row">
                                <div class="div-full-name">
                                    <label>NOME COMPLETO</label>
                                    <div class="">
                                        <input type="text" v-model="fullName" :class="{ 'disabled': !isAdmin }">
                                    </div>
                                </div>
                            </div>

                            <div class="div-row">
                                <div class="div-gender">
                                    <label>GÉNERO</label>
                                    <div>
                                        <select v-model="genderId" :class="{ 'disabled': !isAdmin }">
                                            <option value="null">--</option>
                                            <option :value="gender.id" v-for="(gender, index) in userData.gender"
                                                :key="index">
                                                {{
                                                    gender.designation }}</option>
                                        </select>
                                    </div>

                                </div>
                                <div class="div-category">
                                    <label>CATEGORIA</label>
                                    <div class="">
                                        <select v-model="categoryId" :class="{ 'disabled': !isAdmin }">
                                            <option value="null">--</option>
                                            <option :value="cat.id" v-for="(cat, index) in userData.categories"
                                                :key="index">{{
                                                    cat.designation }}</option>
                                        </select>
                                    </div>
                                </div>
                            </div>

                            <div class="div-row" v-if="props.userId">
                                <div class="div-teams">
                                    <label>EQUIPA(S)</label>
                                    <div class="div-team-content">
                                        <div v-for="(team, index) in userData.teams" :key="index"
                                            class="div-team-container">
                                            <div @mouseover="Gems.TooltipIfNeeded($event.target)">
                                                <div class="div-team line-clamp line-clamp-1">
                                                    {{ team.designation }}</div>
                                            </div>
                                        </div>
                                        <div class="div-team-container" v-if="!hasTeams">
                                            <div>
                                                <div class="div-no-team">--</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <!-- <div class="div-hour-container" v-if="hasIntime">
                            <div class="div-hour-content">
                                <label>VALOR/HORA</label>
                                <div class="div-hour">
                                    <input type="text" v-model="hourValue" :class="{ 'disabled': !isAdmin }">
                                </div>
                            </div>

                            <div class="div-hour-content">
                                <label>CUSTO/HORA</label>
                                <div class="div-hour">
                                    <input type="text" v-model="costHour" :class="{ 'disabled': !isAdmin }">
                                </div>
                            </div>

                            <div class="div-hour-content">
                                <label>HORAS DIÁRIAS</label>
                                <div class="div-hour">
                                    <input type="text" v-model="dailyHours" :class="{ 'disabled': !isAdmin }">
                                </div>
                            </div>
                        </div> -->

                    </div>

                    <div id="div-right" class="div-right">

                        <div class="div-right-container">
                            <div class="div-row">
                                <div class="div-function">
                                    <label>FUNÇÃO</label>
                                    <div class="">
                                        <select v-model="jobId" :class="{ 'disabled': !isAdmin }">
                                            <option value="null">--</option>
                                            <option :value="job.id" v-for="(job, index) in userData.jobs" :key="index">
                                                {{ job.designation }}
                                            </option>
                                        </select>
                                    </div>
                                </div>
                            </div>

                            <div class="div-row">
                                <div class="div-mail">
                                    <label>EMAIL</label>
                                    <div class="">
                                        <input type="text" v-model="email" required :class="{ 'disabled': !isAdmin }">
                                    </div>
                                </div>
                            </div>

                            <div class="div-row">
                                <div class="div-username">
                                    <label>USERNAME</label>
                                    <div class="">
                                        <input type="text" v-model="username" required
                                            :class="{ 'disabled': !isAdmin }">
                                    </div>
                                </div>
                            </div>

                            <div class="div-row" v-if="props.userId">
                                <div class="div-headships">
                                    <label>CHEFIA(S) DIRETA(S)</label>
                                    <div class="div-headships-content">
                                        <div v-for="(head, index) in userData.hierarchy" :key="index">
                                            <div @mouseover="Gems.TooltipIfNeeded($event.target)">
                                                <div class="div-headship-user line-clamp line-clamp-1">
                                                    {{ head.headship_name }}
                                                </div>
                                            </div>
                                        </div>
                                        <div v-if="!hasHeadship">
                                            <div>
                                                <div class="div-headship-user">--</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>


                        </div>

                        <div class="div-update-user-info">
                            <div class="inputs-border" :class="{ 'hidden': !isAdmin, 'hover-inputs-border': isAdmin }">
                                <button id="btn-edit-user" class="button" @click="UpdateUserInfo()">{{ props.userId ?
                                    "GRAVAR" : "CRIAR UTILIZADOR" }}</button>
                            </div>
                        </div>

                    </div>

                </div>


            </div>
        </div>
    </div>
</template>

<style scoped>
select,
input {
    font-family: "Poppins", sans-serif;
    background-color: var(--default-input-background);
    border: none;
    padding-left: 5px;
}

label {
    margin-bottom: 3px;
}

.div-hour {
    margin-top: 3px;
}

.div-container {
    gap: 20px;
}

.div-user-content {
    display: flex;
    flex-direction: column;
}

.div-modal-content {
    padding: 0px 14px 18px 16px;
}

.div-close-modal {
    display: flex;
    justify-content: end;
    padding-top: 16px;
    z-index: 11;
}

.div-user-image-container {
    margin-top: -20px;
}

.div-user-image-container,
.div-user-image {
    display: flex;
    justify-content: center;
    border-radius: 50%;
}

.div-user-image-content,
.div-user-image img {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 140px;
    width: 140px;
    border-radius: 50%;
}

.div-user-image img {
    cursor: pointer;
}

.fa-times {
    font-size: 1.5rem;
}

.div-gender,
.div-internal {
    width: 30%;
}

.div-username input,
.div-mail input,
.div-function select,
.div-category select,
.div-gender select,
.div-full-name input,
.div-professional-name input,
.div-internal input {
    height: 35px;
    width: 100%;
    color: white;
    border-radius: 7px;
}

.div-category,
.div-professional-name {
    width: 70%;
}

.div-headships,
.div-username,
.div-teams,
.div-mail,
.div-function,
.div-full-name {
    width: 100%;
}

.div-team,
.div-headship-user {
    flex-direction: row !important;
}

.div-user-info-container {
    display: grid;
    grid-template-columns: 50% 50%;
    gap: inherit;
}

.div-row div,
.div-container,
.div-full-name,
.div-professional-name {
    display: flex;
    flex-direction: column;
}


.div-headship-user,
.div-no-team,
.div-team {
    align-items: center;
    justify-content: center;
    border: 1px solid grey;
    background-color: black;
    border-radius: 7px;
    width: 100%;
    line-height: 2;
    height: 40px;
    align-content: center;
}

.div-row {
    display: flex;
    align-items: center;
    justify-content: start;
    gap: 10px;
    width: 95%;
}

.div-left-container,
.div-right-container,
.div-right,
.div-left {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.div-left {
    justify-content: space-between;
}

.div-headships-content {
    gap: 5px;
}

.div-headship-user {
    border-radius: 7px;
    background-color: black;
    border: 1px solid grey;
    height: 40px;
    align-items: center;
    justify-content: center;
    text-align: center;
    align-content: center;
}

.div-team-content {
    width: 100%;
}

.div-team-container {
    text-align: center;
}

.div-update-user-info {
    display: flex;
    justify-content: start;
    align-items: end;
    height: 100%;
    width: 100%;
}

.div-update-user-info div {
    width: 95%;
}

.disabled {
    opacity: 1;
}

.inputs-border:has(.disabled):hover {
    background: var(--input-gradient-border);
}

.div-hour-container {
    display: grid;
    grid-template-columns: 33.3% 33.3% 33.3%;
    width: 95%;
    gap: 1.6%;
}

.div-hour-content div {
    width: 90%;
}

.div-hour-content input {
    width: 100%;
}

.div-hour {
    display: flex;
}

.div-hour input {
    border-radius: 7px;
    height: 35px;
}

.div-team-content {
    display: flex;
    flex-direction: column;
    gap: 5px;
}

@media screen and (max-width: 1280px) {

    .div-headship-user,
    .div-team {
        padding: 1px 10px !important;
        line-height: 3;
    }
}

@media screen and (min-width: 1300px) and (max-width: 1530px) {

    .div-headship-user,
    .div-team {
        padding: 3px 10px !important;
    }
}

@media screen and (max-width: 1750px) {

    .div-headship-user,
    .div-team {
        padding: 2px 10px;
    }
}
</style>
